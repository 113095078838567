import styled from 'styled-components';
import { offWhite } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';
import { brNorm } from 'constants/theme';

export const Ad = styled.div`
  max-width: 1400px;
  margin: 3rem 2rem;
  border-radius: ${brNorm};
  background-color: white;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  overflow: hidden;

  ${MEDIA.TABLET`
      margin: 2rem 1rem;
  `};

  ${MEDIA.PHONE`
      margin: 2rem 0rem;
  `};

  ${MEDIA.TABLET`
      flex-direction: column;
   `};

  .left {
    width: 100%;
    height: 30rem;
    background: ${offWhite};
  }

  .right {
    text-align: center;
    padding: 4rem;

    .title {
      font-size: 2.2rem;
      font-weight: 700;
      margin-bottom: 2rem;
      padding: 0 4rem;

      ${MEDIA.LARGE_DESKTOP`
        padding: 0;
      `};
    }

    a {
      margin-top: 1rem;
    }

    .description {
      line-height: 1.2;
      margin-bottom: 2.5rem;
    }

    .cta {
      margin-bottom: 2rem;
    }

    .read-more {
      margin-top: 4rem;
      font-weight: 700;
      text-decoration: underline;
    }
  }
  /* } */
`;
