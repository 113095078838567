import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import GradientLink from '../gradient-link';
import { Ad } from './ad.css';

const _Ad = ({ ad }) => {
  const imageData = ad.image.childImageSharp.fluid;

  return (
    <Ad>
      <div className="left">
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          style={{
            backgroundPosition: 'center center',
            width: '100%',
            height: '30rem',
          }}
        ></BackgroundImage>
      </div>
      <div className="right">
        <h4 className="title">{ad.title}</h4>
        <p className="description">{ad.copy}</p>
        <GradientLink
          className="cta"
          href={ad.cta_link}
          target="_blank"
          rel="noreferrer"
        >
          {ad.cta}
        </GradientLink>
      </div>
    </Ad>
  );
};

_Ad.propTypes = {
  ad: PropTypes.object.isRequired,
};

export default _Ad;
